import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import saveIcon from "../../../icons/common/save.png";
import closeIcon from "../../../icons/common/close.png";
import activeIcon from "../../../icons/common/active.png";
import inactiveIcon from "../../../icons/common/inactive.png";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  getAccessMgmtEndPoint,
  getStatesByCountryCode,
  updateAccessReqEndPoint,
} from "../utils";
import axios from "axios";
import notify from "../../../services/toasterService";
import { setStatesList, setUploadedImage, setUserAccessData } from "../accessmgmtReducer";
import { setRowData } from "../../grid/gridReducer";
import CountryDropdownPreview from "../../ContactsDetailsPanel/mobile-field-component Preview";

const UserContactPreview = () => {
  const dispatch = useDispatch();
  const [userContactInfo, setUserContactInfo] = useState<any[]>([]);
  const [showAdd, setShowAdd] = useState(true);
  const selRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );
  const [statesList, setStateList] = useState<any>([]);

  const mobileWithFlag = useSelector(
    (state: RootState) =>
      state.contactReducer?.selectedMobileWithCountryFlagPreview
  );
  const altMobileWithFlag = useSelector(
    (state: RootState) =>
      state.contactReducer?.selectedAltMobileWithCountryFlagPreview
  );
  const faxWithFlag = useSelector(
    (state: RootState) =>
      state.contactReducer?.selectedFaxWithCountryFlagPreview
  );
  const phoneCountryCode = useSelector(
    (state: RootState) =>
      state.contactReducer?.selectedCountryCodeForMobilePreview
  );
  const AltPhoneCountryCode = useSelector(
    (state: RootState) =>
      state.contactReducer?.selectedCountryCodeForAltMobilePreview
  );
  const faxCountryCode = useSelector(
    (state: RootState) => state.contactReducer?.selectedCountryCodeForFaxPreview
  );
  // const statesList = useSelector(
  //   (state: RootState) => state.contactReducer?.selectedCountryCodeForFaxPreview
  // );


  useEffect(() => {
    if (selRowData && selRowData?.length > 0) {
      getStatesList(selRowData[0].countryCode?.isoCountryCode);
      setUserContactInfo(selRowData);
    
      dispatch(setUploadedImage([]));
      //  setRemoveSelModules([]);
      // setModules([]);
    }
    // Write for onload
  }, [selRowData]);

  let uploadedImageFile = useSelector(
    (state: RootState) => state.accessMgmtReducer.uploadedImage
  );

  const languagesDataList = useSelector(
    (state: RootState) => state.accessMgmtReducer.languagesData
  );
  const timeZonesDataList = useSelector(
    (state: RootState) => state.accessMgmtReducer.timeZonesData
  );

  const countryCodesDataList = useSelector(
    (state: RootState) => state.accessMgmtReducer.countryCodesList
  );

  /**
   * Changing status for Preview selected tabs
   * @param flag - true/false
   */
  const changeStatusForRowData = (flag: any) => {
    if (flag === false) {
      setUserContactInfo([
        { ...userContactInfo[0], activeForGrid: "Inactive" },
      ]);
    } else {
      setUserContactInfo([{ ...userContactInfo[0], activeForGrid: "Active" }]);
    }
  };

  /* Get States WS Call */
  const getStatesList = async (countryCode: any) => {
    const endPointUrl = getStatesByCountryCode(countryCode);
    await axios.get(endPointUrl).then(
      (res) => {
        setStateList(res.data);
      
      // dispatch(setStatesList(res.data));
      },
      (err) => {
        notify("Failed to fetch data", "error");
      }
    );
  };

  /* Update Users WS Call */
  const updateUserContactTabApi = async (data: any) => {
    // const token = sessionStorage.getItem('token');
    // const token = UserService.getToken()
    const endPointURL = updateAccessReqEndPoint("Users");
  
    let body = {
      // userId: userContactInfo[0]?.company

      firstname: userContactInfo[0]?.firstname,
      lastname: userContactInfo[0]?.lastName,
      company: userContactInfo[0]?.company,
      jobTitle: userContactInfo[0]?.jobTitle,
      username: userContactInfo[0]?.username,
      roleId: userContactInfo[0]?.roleId,
      reclaimCenterId: userContactInfo[0]?.reclaimCenterId,
      endDate: userContactInfo[0]?.endDate,

      email: userContactInfo[0]?.email,
      phoneNumber:
        mobileWithFlag !== undefined &&
        mobileWithFlag !== null &&
        mobileWithFlag !== ""
          ? mobileWithFlag?.toString().replace(/[^0-9]/g, "")
          : userContactInfo[0]?.phoneNumber,
      fax:
        faxWithFlag !== undefined && faxWithFlag !== null && faxWithFlag !== ""
          ? faxWithFlag?.toString().replace(/[^0-9]/g, "")
          : userContactInfo[0]?.fax,
      language: userContactInfo[0]?.language,
      timezone: userContactInfo[0]?.timezone,
      countryId: userContactInfo[0]?.countryId,
      stateId: userContactInfo[0]?.stateId,
      city: userContactInfo[0]?.city,
      street: userContactInfo[0]?.street,

      password: userContactInfo[0]?.password,
      storeId: userContactInfo[0]?.storeId,
      userType: userContactInfo[0]?.userType,
      userId: userContactInfo[0]?.userId,
      id: userContactInfo[0]?.id,
      status: userContactInfo[0]?.activeForGrid,
      scanUser: userContactInfo[0]?.scanUser !== undefined &&  userContactInfo[0]?.scanUser !== null ?  userContactInfo[0]?.scanUser : {}
    };
    if (
      uploadedImageFile.length > 0 &&
      uploadedImageFile !== [] &&
      uploadedImageFile !== undefined
    ) {
      Object.assign(body, { image: uploadedImageFile[0].base64 });
    } else {
      Object.assign(body, { image: userContactInfo[0]?.image });
    }
    if (userContactInfo[0].userType === "External") {
      if (
        userContactInfo[0]?.scanUser !== undefined &&
        userContactInfo[0]?.scanUser !== null &&
        userContactInfo[0]?.scanUser !== ""
      ) {
        Object.assign(body, {
          scanUser: {
            binMaxScanQty: userContactInfo[0]?.scanUser?.binMaxScanQty,
            boxMaxScanQty: userContactInfo[0]?.scanUser?.boxMaxScanQty,
            scanMaxQty: userContactInfo[0]?.scanUser?.scanMaxQty,
            canCloseBin: userContactInfo[0]?.scanUser?.canCloseBin,
            hourlyScanGoal: userContactInfo[0]?.scanUser?.hourlyScanGoal,
            supervisorPassword:
              userContactInfo[0]?.scanUser?.supervisorPassword,
            id: userContactInfo[0]?.scanUser?.id,
          },
        });
      } else {
        Object.assign(body, { scanUser: {} });
      }
    }
    //  await axios.post(endPointURL, body, { headers: { 'Authorization': `Bearer ${token}` } }).then(
    await axios
      .post(endPointURL, body)
      .then(
        // (res) => {
        //     notify('User updated successfully', 'success');
        //     getUsers('Users');
        // }
        (res: any) => {
          if (res.data.status !== 200) {
            notify(res.data.message, "error");
          } else {
            notify(res.data.message, "success");
            getUsers("Users");
           
          }
          // clearPreview();
        }
      )
      .catch((err) => {
        console.log(err, "Users");
        notify("Failed to update user", "error");
      });
  };

  /* Get Users WS Call */
  const getUsers = async (fieldName: any) => {
    const getEndPointUrl = getAccessMgmtEndPoint(fieldName);
    // await axios.get(getEndPointUrl, { headers: { 'Authorization': `Bearer ${token}` } }).then((res: { data: any; }) => {
    await axios
      .get(getEndPointUrl)
      .then((res: { data: any }) => {
        dispatch(setUserAccessData(res.data));
        let dataa = res.data[0];
        setTimeout(() => {
          setUserContactInfo(res.data);
          dispatch(setRowData(userContactInfo));
        }, 100);
      })
      .catch((err: any) => {
        console.log(err);
        notify(
          "Error! Unable to fetch data. Please try after sometime.",
          "error"
        );
      });
  };

  const clearPreview = () => {
    dispatch(setRowData([]));
    dispatch(setUploadedImage([]));
  };

  /* Update user fields  */
  const userInfoFieldFocusOut = (e: any, key: any) => {
    if (key === "zip") {
      if (e.value.length <= 5) {
        setUserContactInfo([{ ...userContactInfo[0], [key]: e.value }]);
      }
    } else {
      setUserContactInfo([{ ...userContactInfo[0], [key]: e.value }]);
    }
  };

  /* Update Users fields*/
  const userFieldsSelectFocusOut = (event: any, key: any) => {
    const dataa = JSON.parse(event.target.value);
    
    if (key === "countryId") {
      getStatesList(dataa.isoCountryCode);
      //  setMobileUserSelectFieldsObj([{ ...mobileUserSelectFieldsObj[0], [key]: dataa}])
      setUserContactInfo([{ ...userContactInfo[0], [key]: dataa.id }]);
    }
    // setMobileUserSelectFields([{...mobileUserSelectFields[0], [key]: dataa}])
  
    if (key === "stateId") {
      //  setMobileUserSelectFieldsObj([{ ...mobileUserSelectFieldsObj[0],[key]: dataa}])
      setUserContactInfo([{ ...userContactInfo[0], [key]: dataa }]);
    }
    if (key === "language") {
      // setMobileUserSelectFieldsObj([{ ...mobileUserSelectFieldsObj[0],[key]: dataa}])
      setUserContactInfo([{ ...userContactInfo[0], [key]: dataa.isoLongCode }]);
    }
    if (key === "timezone") {
      // setMobileUserSelectFieldsObj([{ ...mobileUserSelectFieldsObj[0],[key]: dataa}])
      setUserContactInfo([
        { ...userContactInfo[0], [key]: dataa.timezoneName },
      ]);
    }
   
    // setUserContactInfo([{ ...userContactInfo[0], [key]: event.target.value }]);
  };

  const formatInput = (e: any) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  /* User Contact preview tab  */
  const UserContactPreviewData = (data: any) => {
    return (
      <div className="col-sm-12 px-3 py-1 ">
        <div className="row">
          <div className="col-sm-12 px-0 pt-2 ">
            <div className="col-sm-6 col-md-6 col-lg-4 preview-Key" style={{lineHeight: '32px'}}>
              Email
            </div>
            <div className="col-sm-6 col-md-6 col-lg-8 p-0 preview-value ">
              <input
                type="text"
                className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus accMgmtFields boxShadow-None pl-0"
                value={userContactInfo[0]?.email}
                onChange={(e: any) => userInfoFieldFocusOut(e.target, "email")}
                onBlur={(e: any) => userInfoFieldFocusOut(e.target, "email")}
                onKeyUp={(e: any) => userInfoFieldFocusOut(e.target, "email")}
              />
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput"  text={userContactInfo[0]?.email} labelClassName="txtEditClassName"
                        onFocusOut={(e) => userInfoFieldFocusOut(e, 'email')}  labelPlaceHolder='---'/> */}
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4 ">
            <div className="col-sm-6 col-md-6 col-lg-4 preview-Key  ">
              Phone
            </div>
            <div className="col-sm-6 col-md-6 col-lg-8 p-0 preview-value p-0 ">
              <CountryDropdownPreview
                type="Phone"
                value={userContactInfo[0]?.phoneNumber}
              />
              {/* <input type="text"  className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus accMgmtFields boxShadow-None pl-0"  value={userContactInfo[0]?.phoneNumber}
                       onChange={(e: any)=>userInfoFieldFocusOut(e.target, 'phoneNumber')} onBlur={(e: any)=>userInfoFieldFocusOut(e.target, 'phoneNumber')} onKeyUp={(e: any)=>userInfoFieldFocusOut(e.target, 'phoneNumber')}/> */}
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput"  text={userContactInfo[0]?.phoneNumber} labelClassName="txtEditClassName"
                        onFocusOut={(e) => userInfoFieldFocusOut(e, 'phoneNumber')}  labelPlaceHolder='---'/> */}
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4 ">
            <div className="col-sm-6 col-md-6 col-lg-4 preview-Key  ">Fax</div>
            <div className="col-sm-6 col-md-6 col-lg-8 p-0 preview-value p-0">
              <CountryDropdownPreview
                type="Fax"
                value={userContactInfo[0]?.fax}
              />
              {/* <input type="text"  className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus accMgmtFields boxShadow-None pl-0"  value={userContactInfo[0]?.fax}
                       onChange={(e: any)=>userInfoFieldFocusOut(e.target, 'fax')} onBlur={(e: any)=>userInfoFieldFocusOut(e.target, 'fax')} onKeyUp={(e: any)=>userInfoFieldFocusOut(e.target, 'fax')}/> */}
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput"  text={userContactInfo[0]?.fax} labelClassName="txtEditClassName"
                        onFocusOut={(e) => userInfoFieldFocusOut(e, 'fax')}  labelPlaceHolder='---'/> */}
            </div>
          </div>
        </div>
        <hr className="previewScreenDevider"></hr>
        <div className="row">
          <div className="col-sm-12 px-0 pt-4 ">
            <div className="col-sm-6 col-md-6 col-lg-4 preview-Key  pt-1">
              Language
            </div>
            <div className="col-sm-6 col-md-6 col-lg-8 p-0 preview-value ">
              <select
                className="form-control border-none storeNumber accMgmtFields"
                onChange={(e) => userFieldsSelectFocusOut(e, "language")}
                defaultValue={userContactInfo[0]?.language}
              >
                {/* <option value={userContactInfo[0]?.language ? userContactInfo[0]?.language : 'Select'}>{userContactInfo[0]?.language ? userContactInfo[0]?.language : 'Select'}</option> */}
                {languagesDataList?.map((lang: any) => (
                  <option value={JSON.stringify(lang)}>
                    {lang?.isoLongCode}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4 ">
            <div className="col-sm-6 col-md-6 col-lg-4 preview-Key  pt-1">
              Time Zone
            </div>
            <div className="col-sm-6 col-md-6 col-lg-8 p-0 preview-value ">
              <select
                className="form-control border-none storeNumber accMgmtFields"
                defaultValue={userContactInfo[0]?.timezone}
                onChange={(e) => userFieldsSelectFocusOut(e, "timezone")}
              >
                {/* <option value={userContactInfo[0]?.timezone}>{userContactInfo[0]?.timezone}</option> */}
                {timeZonesDataList?.map((tz: any) => (
                  <option value={JSON.stringify(tz)}>
                    {tz.timezoneName}
                    {tz.utc}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <hr className="previewScreenDevider"></hr>
        <div className="row">
          <div
            className="col-sm-12 px-0 p-2"
            style={{ backgroundColor: "#e8e8e8", color: "#585757", fontWeight: 'bold', letterSpacing: '1px' }}
          >
            <span className="font-14 float-left px-3 py-1">Address</span>
          </div>

          <div className="col-sm-12 px-0 pt-4 ">
            <div className="col-sm-6 col-md-6 col-lg-4 preview-Key  pt-1">
              Country
            </div>
            <div className="col-sm-6 col-md-6 col-lg-8 p-0 preview-value ">
              <select
                className="form-control border-none storeNumber accMgmtFields"
                onChange={(e) => userFieldsSelectFocusOut(e, "countryId")}
              >
                <option value={userContactInfo[0]?.countryId}>
                  {userContactInfo[0]?.countryName}
                </option>
                {countryCodesDataList?.map((c: any) => (
                  <option value={JSON.stringify(c)}>{c.countryName}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4 ">
            <div className="col-sm-6 col-md-6 col-lg-4 preview-Key  pt-1">
              State 
            </div>
            <div className="col-sm-6 col-md-6 col-lg-8 p-0 preview-value ">
              <select
                className="form-control border-none storeNumber accMgmtFields"
                onChange={(e) => userFieldsSelectFocusOut(e, "stateId")}
              >
                <option value={userContactInfo[0]?.state?.id}>
                  {userContactInfo[0]?.state?.stateName}
                </option>
                {statesList?.map((st: any) => (
                  <option value={st.id}>{st.stateName}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4 ">
            <div className="col-sm-6 col-md-6 col-lg-4 preview-Key" style={{lineHeight: '28px'}}>City</div>
            <div className="col-sm-6 col-md-6 col-lg-8 preview-value p-0">
              <input
                type="text"
                className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus accMgmtFields boxShadow-None pl-0"
                value={userContactInfo[0]?.city}
                onChange={(e: any) => userInfoFieldFocusOut(e.target, "city")}
                onBlur={(e: any) => userInfoFieldFocusOut(e.target, "city")}
                onKeyUp={(e: any) => userInfoFieldFocusOut(e.target, "city")}
              />
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput"  text={userContactInfo[0]?.city} labelClassName="txtEditClassName"
                            onFocusOut={(e) => userInfoFieldFocusOut(e, 'city')}  labelPlaceHolder='---'/> */}
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4 ">
            <div className="col-sm-6 col-md-6 col-lg-4 preview-Key" style={{lineHeight: '28px'}}>Zip</div>
            <div className="col-sm-6 col-md-6 col-lg-8 p-0 preview-value ">
              <input
                type="number"
                className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus accMgmtFields boxShadow-None"
                maxLength={5}
                value={userContactInfo[0]?.zip}
                onChange={(e: any) => userInfoFieldFocusOut(e.target, "zip")}
                onBlur={(e: any) => userInfoFieldFocusOut(e.target, "zip")}
                onKeyUp={(e: any) => userInfoFieldFocusOut(e.target, "zip")}
                style={{paddingLeft: '17px'}}
              />
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput"  text={userContactInfo[0]?.zip} labelClassName="txtEditClassName"
                            onFocusOut={(e) => userInfoFieldFocusOut(e, 'zip')}  labelPlaceHolder='---'  inputMaxLength={5}  /> */}
            </div>
          </div>
          <div className="col-sm-12 px-0 pt-4 ">
            <div className="col-sm-6 col-md-6 col-lg-4 preview-Key" style={{lineHeight: '28px'}}>
              Street
            </div>
            <div className="col-sm-6 col-md-6 col-lg-8 preview-value p-0">
              <input
                type="text"
                className="form-control boxShadow-None border-none cursor-pointer txtEditFieldFocus accMgmtFields boxShadow-None pl-0"
                value={userContactInfo[0]?.street}
                onChange={(e: any) => userInfoFieldFocusOut(e.target, "street")}
                onBlur={(e: any) => userInfoFieldFocusOut(e.target, "street")}
                onKeyUp={(e: any) => userInfoFieldFocusOut(e.target, "street")}
              />
              {/* <InlineInputEdit inputClassName="txtEditClassNameInput"  text={userContactInfo[0]?.street} labelClassName="txtEditClassName"
                            onFocusOut={(e) => userInfoFieldFocusOut(e, 'street')}  labelPlaceHolder='---' /> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  /* User Contact preview footer  */
  const footerPreview = (data: any) => {
    return (
      <div className="col-sm-12 px-0 pt-5 ">
        <div className="col-sm-6 col-md-6 col-lg-6 pl-1.5rem pr-1 preview-Key  ">
          <ButtonComponent
            cssClass="e-outline opt-btn-light float-left"
            onClick={() => updateUserContactTabApi(data)}
          >
            <img src={saveIcon} alt="Save" />
          </ButtonComponent>

          <ButtonComponent
            cssClass="e-outline opt-btn-light float-left ml-3"
            onClick={() => clearPreview()}
          >
            <img src={closeIcon} alt="Close" />
          </ButtonComponent>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 preview-value pt-3">
          <p className="preview-status">
            {data?.activeForGrid === "Active" ||
            data?.activeForGrid === "active" ? (
              <span>
                <img
                  src={activeIcon}
                  alt="Status"
                  onClick={() => changeStatusForRowData(false)}
                />
                &nbsp; &nbsp; Active
              </span>
            ) : (
              <span>
                <img
                  src={inactiveIcon}
                  alt="Status"
                  onClick={() => changeStatusForRowData(true)}
                />
                &nbsp; &nbsp; Inactive
              </span>
            )}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div>
      {userContactInfo && userContactInfo.length > 0 ? (
        <div className="col-sm-12 px-3 py-3 user-preview-details">
          <div className="col-sm-12 px-0 pt-2 pb-4">
            {UserContactPreviewData(userContactInfo[0])}
          </div>
          <div className="col-sm-12 px-0 pt-2 pb-4">
            {footerPreview(userContactInfo[0])}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default UserContactPreview;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { addMasterDataEndPoint, getEndPoint } from "./utils";
import axios from "axios";
import { ContactType } from "./constants";
import ContactsDetailspanel from "../ContactsDetailsPanel/ContactsDetailsPanel";
import Grid from "../grid/Grid";
import { config } from "../grid/config";
import LLModal from "../common/llModal/LLModal";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import userIcon from "../../assets/userIcon.png";
import notify from "../../services/toasterService";
import {
  setTabHeading,
  setTabNameWHLocation,
  setWHLocUPCDefaultData,
} from "./contactReducer";
import { ReactComponent as Add } from "../../icons/AddNew.svg";

const UPCDefaultView = () => {
  const [showModel, setShowModel] = useState(Boolean);
  const [uPCDispoUPCDefault, setUPCDispoUPCDefault] = useState("");
  const [whLocUPCDefault, setWHLocUPCDefault] = useState("");
  const [voiceUPCDefault, setVoiceUPCDefault] = useState("");
  const [maxPalletUPCDefault, setMaxPalletUPCDefault] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [rowInfo, setRowInfo] = useState<any[]>([]);
  let tabName: any = ContactType.UPCDefaultWHLoc;
  const dispatch = useDispatch();
  const selectedRowData = useSelector(
    (state: RootState) => state.gridReducer.selectedRowData
  );
  const dispositionInfo = useSelector(
    (state: RootState) => state.contactReducer?.dispositionData
  );
  const upcDefaultData = useSelector(
    (state: RootState) => state.contactReducer?.WHUPCDefaultData
  );
  useEffect(() => {
    if (upcDefaultData && upcDefaultData?.length > 0) {
      setRowInfo(upcDefaultData);
    }
    dispatch(setTabNameWHLocation(tabName));
    dispatch(setTabHeading(tabName));
  }, [tabName, upcDefaultData]);
  console.log("upcDefaultDataupcDefaultData", upcDefaultData);
  let gridHieght = "calc(100vh - 385px)";
  const handleActionUPCDispoInUPCDefault = (e: any) => {
    setUPCDispoUPCDefault(e.target.value);
  };
  const handleActionWHLocInUPCDefault = (e: any) => {
    setWHLocUPCDefault(e.target.value);
  };
  const handleActionVoiceInUPCDefault = (e: any) => {
    setVoiceUPCDefault(e.target.value);
  };
  const handleActionPalletNumInUPCDefault = (e: any) => {
    setMaxPalletUPCDefault(e.target.value);
  };
  const body = {
    disposition: uPCDispoUPCDefault,
    dispositionVoiceText: voiceUPCDefault,
    warehouseLocation: whLocUPCDefault,
    boxMaxCount: maxPalletUPCDefault,
  };
  const handleAddUPCDefault = () => {
    const endpoint = addMasterDataEndPoint(tabName);
    const getEndpoint = getEndPoint(tabName);
    handleMasterDataChange(endpoint, getEndpoint);
  };
  const handleMasterDataChange = async (
    endpoint: string,
    getEndpoint: string
  ) => {
    //const result = await axios.post(endpoint, body);
    // if (result && result.data) {
    //   setShowModel(false);
    //   notify("Whse Location created successfully", "success");
    // } else {
    //   notify("Failed to create Whse Location", "error");
    // }
    // const getresult = await axios.get(getEndpoint);
    // dispatch(setWHLocUPCDefaultData(getresult.data));

    const result = await axios
      .post(endpoint, body)
      .then((results: any) => {
        if (results && results.data) {
          notify("UPC Default created successfully", "success");
          emptyFieldValues();
        } else {
          notify("Failed to create UPC Default", "error");
        }
      })
      .catch((error: any) => {
        if (error.response) {
          notify(error.response?.data?.message, "error");
        }
      });
    const getresult = await axios.get(getEndpoint);
    dispatch(setWHLocUPCDefaultData(getresult.data));
  };
  const handlePopupActionUPCDefault = () => {
    setShowModel(true);
  };
  const handleCloseUPCPopup = () => {
    setShowModel(false);
    emptyFieldValues();
  };

  const emptyFieldValues = () => {
    let maxBoxPallet = document.getElementById(
      "maxBoxPallet"
    ) as HTMLInputElement;
    let whsLoc = document.getElementById("whsLoc") as HTMLInputElement;
    let voice = document.getElementById("voice") as HTMLInputElement;
    let upcDisp = document.getElementById("upcDisp") as HTMLSelectElement;
    maxBoxPallet.value = "";
    whsLoc.value = "";
    voice.value = "";
    upcDisp.value = "0";
  };

  return (
    <>
      <div className="float-right">
        <span>
          <ButtonComponent
            type="button"
            className="iconCopy float-right ml-1"
            onClick={handlePopupActionUPCDefault}
            style={{ position: "relative", bottom: "4rem" }}
          >
            <Add />
          </ButtonComponent>
          {/* <img
            className="ItemCatImages ml-1rem addPlusIcon upcDefAddImg save-btn"
            src={addIcon}
            onClick={handlePopupActionUPCDefault}/> */}
        </span>
      </div>
      <div className="contacts-wrapper">
        <div className="masterDataGrid default-view">
          <Grid
            config={config[ContactType.UPCDefaultWHLoc]}
            width={"100%"}
            height={gridHieght}
            data={upcDefaultData}
          />
        </div>
        {selectedRowData && selectedRowData.length > 0 ? (
          <div className="detailsTab default-view">
            <ContactsDetailspanel />
          </div>
        ) : (
          <>
            <div className="detailsTabEmpty d-flex align-items-center default-view">
              <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                <p className="selectTextForEmpty">
                  Select Warehouse location to view details{" "}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      <LLModal handleClose={() => setShowModel(false)} show={showModel} width={800}>
        <div>
          <div className="row">
            <div className="row ml-0 mr-0 mb-30 modal-title-header">
              <span className="tabNameForAddScreen">
                Add Warehouse Location
              </span>
            </div>
            </div>
            <div className="row">
            <div className="col-sm-12 textAlign-center">
              <img src={userIcon} className="userAddIcon" />
              {selectedImage && (
                <div className="imagePreviewDiv">
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    className="imagePreview"
                    height="100"
                    width="100"
                  />
                </div>
              )}
            </div>
            </div> 
          
          <div className="col-sm-12 row m-0 mt-2">
            <div className="col-sm-6 mt-1">
            <div className="col-sm-12">
              <label style={{marginBottom: '0'}}>UPC Disposition</label>
              <select
                id="upcDisp"
                className="form-control"
                onChange={(e) => handleActionUPCDispoInUPCDefault(e)}
              >
                <option value="0">Select</option>
                {upcDefaultData?.map((dp) => (
                  <option value={dp.disposition}>{dp.disposition}</option>
                ))}
              </select>
            </div>
            <div className="col-sm-12 mt-1">
              <label style={{marginBottom: '0'}}>Voice</label>
              <input
                id="voice"
                type="text"
                className="form-control"
                onChange={(e: any) => handleActionVoiceInUPCDefault(e)}
              />
            </div>            
            </div>

          
            <div className="col-sm-6 mt-1">            
            <div className="col-sm-12">
              <label style={{marginBottom: '0'}}>Warehouse Location</label>
              <input
                id="whsLoc"
                type="text"
                className="form-control"
                onChange={(e: any) => handleActionWHLocInUPCDefault(e)}
              />
            </div>
            <div className="col-sm-12 mt-1">
              <label style={{marginBottom: '0'}}>Max Box Per Pallet</label>
              <input
                id="maxBoxPallet"
                type="text"
                className="form-control"
                onChange={(e: any) => handleActionPalletNumInUPCDefault(e)}
              />
            </div>
            </div>          
          </div>
          <div className="col-sm-12 px-0 mt-5">
            <ButtonComponent
              cssClass="eg-btn-primary float-right"
              onClick={handleAddUPCDefault}
            >
              ADD WHSE LOCATION
            </ButtonComponent>
            <ButtonComponent
              cssClass="e-control e-btn e-lib eg-btn-primary-link e-flat mt-3"
              onClick={handleCloseUPCPopup}
            >
              CANCEL
            </ButtonComponent>
          </div>
        </div>
      </LLModal>
    </>
  );
};
export default UPCDefaultView;
